.wrapper {
  display: flex;
  width: 100%;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.jc-sb {
  justify-content: space-between !important;
}
.jc-c {
  justify-content: center !important;
}
.jc-fs {
  justify-content: flex-start !important;
}
.jc-fe {
  justify-content: flex-end !important;
}
.ai-c {
  align-items: center !important;
}
.ai-fs {
  align-items: flex-start !important;
}
.ai-fe {
  align-items: flex-end !important;
}
.block {
  padding: 20px 30px;
  flex: 1;
  &.flex-2 {
    flex: 2;
  }
}
.row {
  display: flex;
  &.wrap {
    flex-flow: wrap;
  }
  &--with-spaces {
    width: calc(100% + 20px);
    margin-left: -10px;
    [class^="box"] {
      padding: 0 10px;
    }
  }
  .box-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .box-11 {
    flex-basis: 91.666667%;
    max-width: 91.666667%;
  }
  .box-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .box-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .box-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .box-7 {
    flex-basis: 58.333333%;
    max-width: 58.333333%;
  }
  .box-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .box-5 {
    flex-basis: 41.666667%;
    max-width: 41.666667%;
  }
  .box-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .box-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .box-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .box-1 {
    flex-basis: 8.333333%;
    max-width: 8.333333%;
  }
}
